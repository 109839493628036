import axios from "axios";
import VueAxios from "vue-axios";
import Vue from "vue";
import Vuex from "vuex";
Vue.use(VueAxios, axios);
Vue.use(Vuex);

import { EncryptStorage } from "encrypt-storage";
export const encryptStorage = new EncryptStorage("secret-key-value", {
  prefix: "@occasions",
  encAlgorithm: "Rabbit",
});

export default new Vuex.Store({
  state: {
     def_path: "http://127.0.0.1:8000/",
    // def_path: "https://occasionsapp.tlbak.com/",
    dataTableFooterProps: {
      showFirstLastPage: true,
      "items-per-page-text": "דפים",
    },
    user_occasions: encryptStorage.getItem("user_occasions") || null,
    token: encryptStorage.getItem("token_occasions") || null,
    menu: true,
    occasion: encryptStorage.getItem("occasion") || null,
  },
  getters: {
    user_occasions(state) {
      return state.user_occasions;
    },
    lang: (state) => (section) => {
      let lang = state.lang;
      return lang[section] || [];
    },
    public_lang(state) {
      let lang = state.lang;
      return lang["public"] || [];
    },
    loggedin(state) {
      return state.token != null;
    },
  },
  mutations: {
    clear_occasion(state) {
      encryptStorage.removeItem("occasion");
      state.occasion = null;
    },
    login(state, token) {
      state.token = token;
      console.log(token);
    },
    logout(state) {
      state.token = null;
      encryptStorage.removeItem("token_occasions");
      encryptStorage.removeItem("user_occasions");
    },
    user_occasions(state, user) {
      state.user_occasions = user;
    },
    update_occasion(state, occasion) {
      state.occasion = occasion;
      encryptStorage.setItem("occasion", occasion);
    },
    public__request(state, item) {
      state.pages[item.name] = item.data;
    },
    download_file(state, fileName) {
      axios({
        url: fileName, // File URL Goes Here
        method: "GET",
        responseType: "blob",
        headers: {
          "Content-type": "application/vnd.ms-excel",
          Authorization: `Bearer ${state.token}`,
        },
      }).then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "file.excel");
        document.body.appendChild(link);
        link.click();
      });
    },
  },
  actions: {
    login(context, cred) {
      return new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append("phone", cred.phone);
        formData.append("password", cred.password);
        var config = {
          method: "post",
          url: "auth/login",
          headers: {
            Accept: "application/json",
          },
          data: formData,
        };
        axios(config)
          .then((res) => {
            const token = res.data.data.token;
            const user = res.data.data.user_data;
            encryptStorage.setItem("token_occasions", token);
            // encryptStorage.setItem("user_occasions", JSON.stringify(user));

            context.commit("login", token);
            context.commit("user_occasions", user);
            resolve(res);
            // console.log(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    // public request this function handel any requset type like post & get Etc ...
    public__request(context, cred) {
      return new Promise((resolve, reject) => {
        let config = {
          method: cred.config.method,
          url: cred.config.url,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${context.state.token}`,
          },
          data: cred.data || [],
        };

        axios(config)
          .then((res) => {
            resolve(res);
            if (cred.get) {
              context.commit("public__request", {
                data: res.data,
                name: cred.get,
              });
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
  modules: {},
});
