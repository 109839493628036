<template>
  <v-footer color="primary" class="d-block white--text" height="60px"  app>
      <div class="text-center pt-4 font-weight-bold"> Copyright &copy; 2023</div>
  </v-footer>
</template>

<script>
    export default {
        name:"CustomerFooter"
    }
</script>