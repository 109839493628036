<template>
  <div class="menu">
    <v-navigation-drawer
      class="app-menu"
      v-model="$store.state.menu"
      app
      :right="rtl"
      width="200"
    >
      <v-list nav dense class="pt-10 list d-done">
        <v-list-item to="/employees" link>
          <v-list-item-icon class="me-4">
            <v-icon>settings</v-icon>
          </v-list-item-icon>
          <v-list-item-title> الموطفين </v-list-item-title>
        </v-list-item>
        <!--/ list item -->

        <v-list-item to="/companies" link>
          <v-list-item-icon class="me-4">
            <v-icon>settings</v-icon>
          </v-list-item-icon>
          <v-list-item-title> الشركات </v-list-item-title>
        </v-list-item>
        <!--/ list item -->

        <v-list-item to="/companies_types" link>
          <v-list-item-icon class="me-4">
            <v-icon>settings</v-icon>
          </v-list-item-icon>
          <v-list-item-title> انواع الشركات </v-list-item-title>
        </v-list-item>
        <!--/ list item -->

        <v-list-item to="/clients" link>
          <v-list-item-icon class="me-4">
            <v-icon>settings</v-icon>
          </v-list-item-icon>
          <v-list-item-title> العملاء </v-list-item-title>
        </v-list-item>
        <!--/ list item -->

        <v-list-item to="/expenses" link>
          <v-list-item-icon class="me-4">
            <v-icon>settings</v-icon>
          </v-list-item-icon>
          <v-list-item-title> المصروفات </v-list-item-title>
        </v-list-item>
        <!--/ list item -->

        <v-list-item to="/conditions" link>
          <v-list-item-icon class="me-4">
            <v-icon>settings</v-icon>
          </v-list-item-icon>
          <v-list-item-title> شروط المناسبات </v-list-item-title>
        </v-list-item>
        <!--/ list item -->

        <v-list-item to="/work_time" link>
          <v-list-item-icon class="me-4">
            <v-icon>settings</v-icon>
          </v-list-item-icon>
          <v-list-item-title> ساعات العمل </v-list-item-title>
        </v-list-item>
        <!--/ list item -->

        <v-list-item to="/sections" link>
          <v-list-item-icon class="me-4">
            <v-icon>settings</v-icon>
          </v-list-item-icon>
          <v-list-item-title> اقسام المناسبات </v-list-item-title>
        </v-list-item>
        <!--/ list item -->

        <v-list-item to="/occasions" link>
          <v-list-item-icon class="me-4">
            <v-icon>settings</v-icon>
          </v-list-item-icon>
          <v-list-item-title> المناسبات </v-list-item-title>
        </v-list-item>
        <!--/ list item -->

        <v-list-item to="/forms" link>
          <v-list-item-icon class="me-4">
            <v-icon>settings</v-icon>
          </v-list-item-icon>
          <v-list-item-title> النماذج </v-list-item-title>
        </v-list-item>
        <!--/ list item -->

        <v-list-item to="/calendar" link>
          <v-list-item-icon class="me-4">
            <v-icon>settings</v-icon>
          </v-list-item-icon>
          <v-list-item-title> التقويم </v-list-item-title>
        </v-list-item>
        <!--/ list item -->
      </v-list>

      <!--/ company -->
    </v-navigation-drawer>
  </div>
</template>

<style lang="scss" scoped>
::v-deep {
  .menu {
    @media print {
      display: none;
    }
  }
  .app-menu {
    @media print {
      display: none;
    }
    .v-navigation-drawer__content {
      .list {
        padding-bottom: 100px !important;
      }
      &::-webkit-scrollbar {
        width: 0;
        scrollbar-width: none;
      }
    }
  }
}
</style>

<script>
export default {
  name: "AsideMenu",
  data() {
    return {};
  },
  computed: {
    rtl() {
      return this.$vuetify.rtl;
    },
    keys() {
      return this.$store.getters.lang("menu");
    },
  },
};
</script>
