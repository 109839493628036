<template>
  <div class="login">
    <v-form ref="form" v-model="form" class="pa-5">
      <h1>تسجيل الدخول</h1>
      <v-text-field
        :rules="mail_rules"
        v-model="phone"
        class="mb-10"
        label="رقم الهاتف"
      ></v-text-field>
      <!--/text filed -->
      <v-text-field
        :rules="passwordRules"
        v-model="password"
        :type="!visibility ? 'password' : 'text'"
        label="كلمة المرور"
      >
        <template slot="append">
          <v-btn @click="visibility = !visibility" small icon>
            {{visibility}}
            <v-icon color="grey lighten-1">
              {{ visibility ? "visibility" : "visibility_off" }}
              <!--/ visiblility of password -->
            </v-icon>
          </v-btn>
        </template>
        <!--/ template -->
      </v-text-field>
      <!--/ password  -->
      <div class="d-flex">
        <v-btn
          @click="validate()"
          :disabled="!form || login_loading"
          width="160"
          x-large
          rounded
          color="primary"
          :loading="login_loading"
        >
          دخول
        </v-btn>
        <v-spacer></v-spacer>
        <router-link
          to="/employees"
          class="text-transform-underline black--text font-weight-bold"
        >
        الدعم
        </router-link>
      </div>
    </v-form>
    <!--/ form -->
    <v-snackbar width="auto" right v-model="snackbar.prop">
      {{ snackbar.err ? snackbar.errText : snackbar.succText }}
      <template v-slot:action="{ attrs }">
        <v-icon
          :color="snackbar.err ? snackbar.errColor : snackbar.succColor"
          v-bind="attrs"
          >{{ snackbar.err ? "highlight_off" : "done" }}</v-icon
        >
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  name: "login",
  data() {
    return {
      login_loading: false,
      snackbar: {
        prop: false,
        err: true,
        errColor: "red lighteen-2",
        succColor: "success",
        errText: "رقم الهاتف او  كلمة المرور غير صحيحة",
        succText: "تم التسجيل بنجاح",
      },
      form: false,
      visibility: false,
      phone: "0127823629",
      mail_rules: [(v) => !!v || "Mail number is required"],
      password: "0127823629",
      passwordRules: [(v) => !!v || "Password is required"],
    };
  },
  methods: {
    validate() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        this.login_loading = true; //  make submit button loading
        this.$store
          .dispatch("login", {
            phone: this.phone,
            password: this.password,
          })
          .then((res) => {
            console.log(res);
            this.snackbar.prop = true; // fade alert
            this.snackbar.err = false; // remove error from alert
        //    this.$router.push({ name: "employee" }); // go to employees page
            // this.$store.dispatch("user_informations").then((res) => {
              setTimeout(() => {
                this.$router.push({ name: "occasions" });
              }, 500);
            // });
          })
          .catch((err) => {
            console.log(err.response);
            this.login_loading = false; // remove submit loading
            this.snackbar.prop = true; // fade alert
            this.snackbar.err = true; // fade error of alert
          });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.login {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.v-form {
  width: 28rem;
}
h1 {
  margin-bottom: 100px;
}
</style>
