<template>
  <v-app>
    <AsideMenu
      v-if="$route.name != 'login' && $store.getters.loggedin"
    ></AsideMenu>
    <AppHeader v-if="$route.name != 'login' && $store.getters.loggedin"
      >></AppHeader
    >
    <CustomerHeader
      v-if="$route.name != 'login' && !$store.getters.loggedin"
    ></CustomerHeader>
    <v-main>
      <router-view />
    </v-main>
    <CustomerFooter
      v-if="$route.name != 'login' && !$store.getters.loggedin"
    ></CustomerFooter>
  </v-app>
</template>
<style scoped lang="scss">
::v-deep {
  .v-main {
    @media print {
      padding:0!important;
    }
  }
}
</style>
<script>
import AppHeader from "./layout/header.vue";
import AsideMenu from "./layout/AsideMenu.vue";
import CustomerHeader from "./layout/customer_header.vue";
import CustomerFooter from "./layout/customer_footer.vue";

export default {
  name: "App",
  data: () => ({
    page_keys: {
      pages: [],
    },
  }),
  components: {
    AppHeader,
    AsideMenu,
    CustomerHeader,
    CustomerFooter,
  },
  created() {},
};
</script>
