<template>
    <v-app-bar app color="primary" class="white--text" flat height="75">
      <v-container class="py-0 fill-height">
        <v-spacer> </v-spacer>
        <h1>Anesx</h1>
        <v-spacer> </v-spacer>
      </v-container>
    </v-app-bar>
</template>

<script>
    export default {
        name:"CustomerHeader"
    }
</script>