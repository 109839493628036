import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index";
Vue.use(VueRouter);

const employees = () => import("@/views/employees.vue");
const employee = () => import("@/views/employee/employee.vue");

const companies_types = () => import("@/views/Companies_types.vue");
const companies = () => import("@/views/Companies.vue");
const expenses = () => import("@/views/expenses.vue");
const conditions = () => import("@/views/conditions.vue");
const occasions = () => import("@/views/occasions/occasions.vue");
const answers = () => import("@/views/occasions/answers.vue");
const occasion_report = () => import("@/views/occasions/occasion_report.vue");

const clients = () => import("@/views/clients.vue");
const calendar = () => import("@/views/calendar.vue");
const forms = () => import("@/views/forms.vue");
const set_forms = () => import("@/views/set_forms.vue");
const work_time = () => import("@/views/work_time.vue");
const sections = () => import("@/views/sections.vue");

// =----- start customers -------
const check_occasion = () => import("@/views/customer/check_occasion");
const occasion = () => import("@/views/customer/occasion");

// =----- End customers -------

import login from "@/views/login.vue";

const PageNotFound = () => import("@/views/PageNotFound.vue");

const routes = [
  // ---- start customer
  {
    path: "/check_occasion",
    name: "check_occasion",
    component: check_occasion,
    meta: {
      title: "checkOccasion",
      requiresAuth: false,
    },
  },

  {
    path: "/occasion",
    name: "occasion",
    component: occasion,
    meta: {
      title: "occasion",
      requiresAuth: false,
    },
  },

  /// === end  customer
  {
    path: "/employees",
    name: "employees",
    component: employees,
    meta: {
      title: "employees",
      requiresAuth: true,
    },
  },
  {
    path: "/employee/:id",
    name: "employee",
    component: employee,
    meta: {
      title: "employee",
      requiresAuth: true,
    },
  },
  
  {
    path: "/companies_types",
    name: "companies_types",
    component: companies_types,
    meta: {
      title: "companies_types",
      requiresAuth: true,
    },
  },
  {
    path: "/companies",
    name: "companies",
    component: companies,
    meta: {
      title: "companies",
      requiresAuth: true,
    },
  },

  {
    path: "/expenses",
    name: "expenses",
    component: expenses,
    meta: {
      title: "expenses",
      requiresAuth: true,
    },
  },

  {
    path: "/conditions",
    name: "conditions",
    component: conditions,
    meta: {
      title: "conditions",
      requiresAuth: true,
    },
  },
  {
    path: "/occasions",
    name: "occasions",
    component: occasions,
    meta: {
      title: "occasions",
      requiresAuth: true,
    },
  },
  {
    path: "/answers/:id",
    name: "answers",
    component: answers,
    meta: {
      title: "answers",
      requiresAuth: true,
    },
  },
  {
    path: "/occasion_report/:id",
    name: "occasion_report",
    component: occasion_report,
    meta: {
      title: "occasion_report",
      requiresAuth: false,
      is_public: true,
    },
  },

  {
    path: "/clients",
    name: "clients",
    component: clients,
    meta: {
      title: "clients",
      requiresAuth: true,
    },
  },
  {
    path: "/calendar",
    name: "calendar",
    component: calendar,
    meta: {
      title: "calendar",
      requiresAuth: true,
    },
  },

  {
    path: "/forms",
    name: "forms",
    component: forms,
    meta: {
      title: "forms",
      requiresAuth: true,
    },
  },
  {
    path: "/form/:id",
    name: "set_forms",
    component: set_forms,
    meta: {
      title: "set_forms",
      requiresAuth: true,
    },
  },
  {
    path: "/work_time",
    name: "work_time",
    component: work_time,
    meta: {
      title: "work_time",
      requiresAuth: true,
    },
  },
  {
    path: "/sections",
    name: "sections",
    component: sections,
    meta: {
      title: "sections",
      requiresAuth: true,
    },
  },

  {
    path: "/",
    name: "login",
    component: login,
    meta: {
      title: "login",
      requiresAuth: false,
    },
  },
  {
    path: "*",
    component: PageNotFound,
    meta: {
      title: "Error 404",
    },
  },
  ,
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;

router.beforeEach(async (to, from, next) => {
  document.title = to.meta.title;

  if (!to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters.loggedin) {
      next();
    } else if (to.matched.some((record) => record.meta.is_public)) {
      next();
    } else {
      next("/occasions");
    }
  } else if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters.loggedin) {
      next({
        name: "login",
      });
    } else {
      next();
    }
  }
});
